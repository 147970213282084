<!-- CookiePopup.vue -->
<template>
    <div class="cookie-popup" v-if="showPopup">
        <span class="popup-close" @click="showPopup=false">
            <svg xmlns="http://www.w3.org/2000/svg" width="13.061" height="13.061" viewBox="0 0 13.061 13.061">
                <g id="Group_42964" data-name="Group 42964" transform="translate(-1007.514 -124.514)">
                    <line id="Line_604" data-name="Line 604" x2="12" y2="12" transform="translate(1008.044 125.044)"
                        fill="none" stroke="#5a5b5e" stroke-width="1.5" />
                    <line id="Line_605" data-name="Line 605" x1="12" y2="12" transform="translate(1008.044 125.044)"
                        fill="none" stroke="#5a5b5e" stroke-width="1.5" />
                </g>
            </svg>
        </span>
        <div class="popup-content">
            <h6>Manage Cookie Consent</h6>
            <p>To provide the best experiences, we use technologies like cookies to store and/or access device information.
                Consenting to these technologies will allow us to process data such as browsing behavior or unique IDs on
                this site. Not consenting or withdrawing consent, may adversely affect certain features and functions.</p>
            <button class="btn-primary" @click="acceptCookies">Accept</button>
        </div>
    </div>
</template>
  
<script>
export default {
    data() {
        return {
            showPopup: true,
        };
    },
    methods: {
        acceptCookies() {
            const expirationDate = new Date();
            expirationDate.setDate(expirationDate.getDate() + 365);
            const expires = expirationDate.toUTCString();
            document.cookie = `cookiesAccepted=true; expires=${expires}; path=/`;
            this.showPopup = false;
        },
        showPrivacyPolicy() {
            // Show your privacy policy in a modal or new page
        },
    },
};
</script>
  
  