import VeeValidate from 'vee-validate';


VeeValidate.Validator.localize({
  en:{
      messages:{
          required: (field) => "Please fill " + field + " ."
      }
  }
});
