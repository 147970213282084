import Vue from 'vue'
import moment from 'moment'
import * as _ from "lodash";
import { parsePhoneNumberFromString } from 'libphonenumber-js'


const formatter = new Intl.NumberFormat("en-US", {
	style: "currency",
	currency: "USD",
	minimumFractionDigits: 2
});

Vue.filter('booleanFormat', function (value) {
	return value ? 'Yes' : 'No';

})

Vue.filter('capitalize', function (value) {
	if (!value) return ''
	value = value.toString()
	let arr = value.split(" ")
	let capitalized_array = []
	arr.forEach((word) => {
		let capitalized = word.charAt(0).toUpperCase() + word.slice(1)
		capitalized_array.push(capitalized)
	})
	return capitalized_array.join(" ");
})

Vue.filter('capitalizeCode', function (value) {
	if (!value) return ''
	value = value.toString()
	let arr = value.split("_")

	let capitalized_array = []
	arr.forEach((word) => {

		if (word) {
			let capitalized = word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
			capitalized_array.push(capitalized)

		}


	})


	return capitalized_array.join(" ");
})

Vue.filter('truncate', function (value, limit) {
	return value.substring(0, limit)
})

Vue.filter('tailing', function (value, tail) {
	return value + tail;
})


Vue.filter('formatML', function (item, list) {
	if (item != null && _.get(list, 'length') > 0) {
		var _t = _.find(list, function (aitem) {

			return aitem.id == item
		})
		if (_t) {
			return _t.name
		}
	}

	return ''

})

Vue.filter('formatFullname', function (item) {
	if (item) {
		var _t = '';
		if (item.name != undefined &&item.name != null&&item.name != ' ' && item.name != '' ) return item.name;
		if (item.firstName != null && item.firstName != '') 
		_t = item.firstName;
		if (item.middleName != null && item.middleName != '') 
		_t =_t + " " + item.middleName;
		if (item.lastName != null && item.lastName != '') 
		_t =_t + " " + item.lastName;

		return _t;

	}
}
)

Vue.filter('formatDateTime', function (value) {
	if (value) {
		return moment(String(value)).format('MMM DD, YYYY hh:mm A')
	}
}
)

Vue.filter('countryFormatter', function (value) {

	if (value != null) {
		if (value == "") {
			value = "1"
		}
		if (!(value.includes("+"))) {
			return "+" + value
		}
	}
	return value

}
)

Vue.filter('empty', function (value) {
	if (value == null) {
		return "--";
	}
	return value;
}
)
/*
Vue.filter('addressformat', function (value) {
	var address = '';
	if (value.line1) address = value.line1;
	if (value.line2 && value.line2 !=null && value.line2 !='') address = address + ', ' + value.line2 +(value.locationDetails?", ":'') ;
	if (value.locationDetails) address = address + ' <br/>'+ value.locationDetails.name;
	if (value.stateDetails) address = address + ', ' + value.stateDetails.name + '<br/>';
	if (value.countryDetails) address = address + value.countryDetails.name;
	if (value.zipcode) address = address + ', ' + value.zipcode;
	return address;
}
)
*/

Vue.filter('addressformat', function (value) {
	var lane1 = '';
	var lane2 = '';
	var address = [];
	var finaladdress = '';
	if (value.line1) {
		lane1 = value.line1.trim();
		//lane1 = lane1.split(',').join(' ');
		lane1 = lane1.replace(/ {2,}/g, ' ');
		let lastItem = (lane1.length - 1)
		if (lane1.charAt(0) == ',') {

			lane1 = lane1.slice(1)
		}
		if (lastItem > 0 && lane1.charAt(lastItem) == ',') {

			lane1 = lane1.slice(0, -1)
		}
		if (lane1) {

			address.push(lane1)
		}


	}

	if (value.aptType) address.push(value.aptType)

	if (value.line2 && value.line2 != null && value.line2 != '') {
		lane2 = value.line2.trim();
		//lane2 = lane2.split(',').join(' ');
		lane2 = lane2.replace(/ {2,}/g, ' ');
		let lastItem = (lane2.length - 1)
		if (lane2.charAt(0) == ',') {
			lane2 = lane2.slice(1)
		}
		if (lastItem > 0 && lane2.charAt(lastItem) == ',') {
			lane2 = lane2.slice(0, -1)
		}
		if (lane2) {
			lane2 = lane2.trim();
			address.push(lane2)
		}

	}
	if (_.get(value, 'locationDetails.name')) address.push(value.locationDetails.name);
	if (_.get(value, 'stateDetails.name')) address.push(value.stateDetails.name);
	if (_.get(value, 'countryDetails.name')) address.push(value.countryDetails.name);
	//address = address.map((item)=>item.trim());
	finaladdress = address.join(', ')
	if (_.get(value, 'zipcode')) finaladdress = finaladdress + ', ' + value.zipcode;
	return finaladdress;
}
)




Vue.filter('addformat', function (value) {
	var address = [];
	if (value.location && value.location.name != null && value.location.name != '') address.push(value.location.name);
	if (value.state && value.state.name != null && value.state.name != '') address.push(value.state.name);
	if (value.country && value.country.name != null && value.country.name != '') address.push(value.country.name);
	if (address.length == 0) return null;
	address = address.join(', ')
	return address;
}
)
Vue.filter('formatDatetimeline', function (value) {
	if (value) {
		if (moment(value).startOf('day').isSame(moment().startOf('day'))) {
			return "Today"
		}
		if (moment(value).startOf('day').isSame(moment().add(1, "days").startOf('day'))) {
			return "Tomorrow"
		}
		return moment(value).format('MMM DD, YYYY')
	}
}
)

Vue.filter('formatDate', function (value) {
	if (value) {
		return moment.utc(value).format('MMM DD, YYYY')
	}
})
Vue.filter('formatDateNonUTC', function (value) {
    if (value) {
        return moment(value).format('MMM DD, YYYY')
    }
})
Vue.filter('formatListDate', function (value) {
	if (value) {
		return moment(value).format('MM/DD/YY')
	}
})
Vue.filter('formatTime', function (value) {
	if (value) {
		return moment(value).format('hh:mm A')
	}
}
)

Vue.filter('getObjectName', function (value) {

	if (value['name']) {
		return value['name']
	}
	else {
		return "--";
	}
})
Vue.filter('formatDateMMDD', function (value) {
	if (value) {
		return moment.utc(value).format('MMM DD')
	}
}
)
Vue.filter('formatDateDD', function (value) {
	if (value) {
		return moment.utc(value).format('D')
	}
}
)
Vue.filter('formatDateMMM', function (value) {
	if (value) {
		return moment.utc(value).format('MMM')
	}
}
)

Vue.filter('visastatus', function (value, id) {
	if (value) {
		return _.find(value, { 'id': id }).name;
	}
}
)

Vue.filter('numToText', function (value) {
	let textArray = "zero One Two Three Four Five Six Seven Eight Nine Ten Eleven Twelve Thirteen Fourteen Fifteen Sixteen Seventeen Eighteen Nineteen".split(" ");

	return textArray[value];

}
)


Vue.filter('formatdoctype', function (value) {
	if (value) {
		var doctypes = [
			{
				'key': 'passportVisaI94',
				'name': 'Passport- First page with photo, Visa page and last page with address'
			},
			{
				'key': 'passport',
				'name': 'Passport- First page with photo and last page with address'
			}, {
				'key': 'visa',
				'name': 'Visa'
			}, {
				'key': 'formI94',
				'name': 'Form I-94'
			}, {
				'key': 'formI797',
				'name': 'Form I-797'
			}, {
				'key': 'marriageCertificate',
				'name': 'Marriage Certificate (if spouse is on H-1B, L-1, or F-1 status)'
			}, {
				'key': 'birthCertificate',
				'name': 'Birth Certificate'
			}, {
				'key': 'resume',
				'name': 'Resume'
			}, {
				'key': 'education',
				'name': 'Master’s/ Bachelor’s degree certificate, transcripts, 12th and 10th certificates'
			}, {
				'key': 'expLetters',
				'name': 'Experience Letters'
			}, {
				'key': 'INSNotices',
				'name': 'I-797, Notice of Approval granting the current nonimmigrant status (if applicable)'
			},
			{
				'key': 'formI20',
				'name': 'All Form I-20s (if on F-1 or F2 status)'
			}, {
				'key': 'socialSecurityCardAndProfLicense',
				'name': 'Social Security Card'
			}, {
				'key': 'I140ApprovalNotice',
				'name': 'I–140 Approval Notice'
			},

			{
				'key': 'I797NoticeofApprovalforI140',
				'name': 'I-797, Notice of Approval for I-140 if available'
			},

			{
				'key': 'payStubs',
				'name': 'Three recent pay stubs'
			},
			//offerLetter
			{
				'key': 'offerLetter',
				'name': 'Employment Offer Letter'
			},
			{
				'key': 'employmentAgreement',
				'name': 'Employment Agreement Letter'
			},


			//clientLetter
			{
				'key': 'clientLetter',
				'name': 'Client Letter'
			}
			,
			//vendorLetter
			{
				'key': 'vendorLetter',
				'name': 'Vendor Letter'
			}
			//msa
			,
			//vendorLetter
			{
				'key': 'msa',
				'name': 'MSA/Service Agreement and PO/SOW'
			}
			,
			//po
			{
				'key': 'po',
				'name': 'PO'
			}

			,
			{
				'key': 'other',
				'name': 'Others'
			}
			,
			{
				'key': 'approvalNotice',
				'name': 'Approval Notice'
			},
			{
				'key': 'beneficiaryDocs',
				'name': 'Beneficiary Documents'
			},

			{
				'key': 'h1bRegSelectionNotice',
				'name': 'H-1B Registration Selection Notice'
			},
			{
				'key': 'ead',
				'name': 'EADs (if on OPT or STEM OPT Extension)'
			},
			{
				'key': 'primeVendor',
				'name': 'Prime Vendor Letter'
			},
			{
				'key': 'formI94',
				'name': 'I-94'
			}
			,
			{
				'key': 'priorFormI797',
				'name': 'All prior I-797, Notice of Approvals granting H-1B status'
			},
			{
				'key': 'noticeOfApprovalOfH1Status',
				'name': 'Notice of approval of H1 Status'
			},
			{
				'key': 'approvalNoticeOfPrevH4',
				'name': 'Previous H4 approval notices'
			},
			{
				'key': 'slgEvalOfEduCredentials',
				'name': 'Evaluation of Education certification'
			},
			{
				'key': 'slgEduCredentials',
				'name': 'Education certification'
			},
			{
				'key': 'slgTransScripts',
				'name': 'Transcripts/Marks memo’s'
			},

			{
				'key': "slgAdvancedDegrees",
				'name': "Adavanced Degree Certificate"
			},
			{
				'key': "slgCurPrevH1BH4ApprovalsByINS",
				'name': "Previous H1B & H4 Approvals by INS"
			},
			{
				"key": "slgResume",
				"name": "Current Resume- Please mention the accurate names of the employer and periods of employment. Do not state the client names"
			},
			{
				"key": "slgCollegeDegreesCert",
				"name": "All College Degrees"
			},
			{
				"key": "slgTransScripts",
				"name": "Transcripts/Marks memo’s."
			},
			{
				"key": "slgExpLetters",
				"name": "Letters of Experience"
			},

			{
				"key": "slgPassportAndVisa",
				"name": "Copy of New and Old Passport"
			},
			{
				"key": "slgI94",
				"name": "I-94, front and back"
			},

			{
				"key": "slgPrevNonimmApprovalNotices",
				"name": "Copy of all Previous Nonimmigrant Approval Notices (e.g., H-1B, H-4, L-1A, L-1B, L-2 etc.,)"
			},
			{
				"key": "slgI140OrPermNoties",
				"name": "Copy of any I-140 approval notices or PERM filing Notices (if applicable)"
			},
			{
				"key": "slgPrevLaborApplications",
				"name": "Copy of the previously filed Labor Application, if applicable."
			},

			{
				"key": "slgAckOfPrevLaborApplications",
				"name": "Copy of the Acknowledgement letter for the previously filed Labor application, if applicable. "
			},
			{
				"key": "slgPaystubs",
				"name": "Copies of Most recent paystubs (last 2 to 3 months)."
			},

			{
				"key": "slgI20",
				"name": "Copies of all I-20’s."
			},
			{
				"key": "slgEad",
				"name": "Copies of EAD cards."

			},

			{
				"key": "w2",
				"name": "Copy of all w2"

			},
			{
				"key": "slgEvalOfEduCredentials",
				"name": "Evaluation of Education certification"

			},
			{
				"key": "slgPrevImmApprovalNotices",
				"name": "Copy of all Previous Immigrant Approval Notices (e.g., H-1B, H-4, L-1A, L-1B, L-2 etc.,)"

			},



			//approvalNotice
			//perm Advertisement Documents
			{
				key: 'sunday',
				name: 'Copy of Advertisement',
			},
			{
				key: 'jobFair',
				name: 'Copy of Advertisement',
			},
			{
				key: 'campusRecruitment',
				name: 'Copy of Advertisement',
			},
			{
				key: 'empWebsite',
				name: 'Copy of Advertisement',
			},
			{
				key: 'profOrgOrTrade',
				name: 'Copy of Advertisement',
			},
			{
				key: 'jobSearchWebsite',
				name: 'Copy of Advertisement',
			},
			{
				key: 'pvtEmpmtFirm',
				name: 'Copy of Advertisement',
			},
			{
				key: 'empRefProgram',
				name: 'Copy of Advertisement',
			},
			{
				key: 'campusPlacement',
				name: 'Copy of Advertisement',
			},
			{
				key: 'localNewsPaper',
				name: 'Copy of Advertisement',
			},
			{
				key: 'tvAds',
				name: 'Copy of Advertisement',
			}
		]
		var find = _.find(doctypes, { 'key': value });
		return find ? find.name : "";
	}
}
)

Vue.filter('time', function (value, is24HrFormat = false) {
	if (value) {
		const date = new Date(Date.parse(value));
		let hours = date.getHours();
		const min = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes()
		if (!is24HrFormat) {
			const time = hours > 12 ? 'AM' : 'PM';
			hours = hours % 12 || 12;
			return hours + ':' + min + ' ' + time
		}
		return hours + ':' + min
	}
})

Vue.filter('date', function (value, fullDate = false) {
	value = String(value)
	const date = value.slice(8, 10).trim();
	const month = value.slice(4, 7).trim();
	const year = value.slice(11, 15);

	if (!fullDate) return date + ' ' + month;
	else return date + ' ' + month + ' ' + year;
})

Vue.filter('month', function (val, showYear = true) {
	val = String(val)

	const regx = /\w+\s(\w+)\s\d+\s(\d+)./;
	if (!showYear) {
		return regx.exec(val)[1];
	} else {
		return regx.exec(val)[1] + ' ' + regx.exec(val)[2];
	}

})

Vue.filter('csv', function (value) {
	return value.join(', ')
})

Vue.filter('filter_tags', function (value) {
	return value.replace(/<\/?[^>]+(>|$)/g, "")
})

Vue.filter('k_formatter', function (num) {
	return num > 999 ? (num / 1000).toFixed(1) + 'k' : num
});

Vue.filter('timeago', function (value) {
	if (value) {
		return moment(String(value)).fromNow()
	}
}
)

Vue.filter('formatprice', function (amount) {
	if (amount) {
		return formatter.format(amount);
	}
}
)
Vue.filter('getTwolatters', function (items) {
	// alert(items);
	if (items) {
		let str_arr = items.split(" ");
		if (str_arr.length >= 2) {
			return str_arr[0].charAt(0) + str_arr[1].charAt(0);

		} else {
			return items.charAt(0) + items.charAt(1);

		}

	}
});

//substring(0,8)
Vue.filter('subString', function (items) {
	if (items) {
		if (items.length > 10) {
			return (items.substring(0, 10)) + "....";
		} else {
			return items;
		}

	} else {
		return '';
	}
});
Vue.filter('taskDescription', function (items) {
	if (items) {
		if (items.length > 40) {
			return (items.substring(0, 40)) + "....";
		} else {
			return items;
		}

	} else {
		return '';
	}
});
Vue.filter('reverse', function (items) {
	if (items) {
		return items.slice().reverse()
	}
});

Vue.filter('formatPhone', function (value) {
	if (value) {
		const phoneNumber = parsePhoneNumberFromString(value)
		if (phoneNumber) {
			return phoneNumber && phoneNumber.formatInternational()

		}
		return value.replace(/(\d{3})(\d{3})(\d{4})/, "($1)$2-$3");

	}
});


Vue.filter('formatEmail', function (value) {
	if (value) {

		let splitStringArray = value.split('<')

		if (splitStringArray.length > 1) {
			return splitStringArray[1].slice(0, splitStringArray[1].length - 1)
		} else {
			return value
		}

	}
});