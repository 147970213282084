


const getters = {
  isLoggedIn: state => !!state.token,
  authStatus: state => state.status,
  getuser: state => state.user,
  getuserRole: state => state.userRole,
  getToken:state => state.token,
  getRestaurantId:state => state.restaurantId,
}

export default getters