

export default {
    _APIKEY:"FV$HSE@JUGUUGU$J5L@HE",
    //_TENANTID:"5db7d79d6032453bd060ed9c",
    _TENANTID:"6114b46173c70c03e453e2df",
   // _BASEURL: 'https://intakedev.innvectra.in',
   // _BASEURL: 'https://anyclap.com/app',
    _BASEURL: window.location.origin+'/app',
   // _BASEURL: 'http://localhost:8080/app',
    _APIURL: window.location.origin+'/api',
    //_APIURL: "https://staging.kafeinfo.com/api",
    _S3URL:'https://intakeportal.s3.amazonaws.com/',
    _S3URLAWS:'https://s3.amazonaws.com/intakeportal/',
    _POWEREDBY:"https://immibox.com",
    _TERMS:"https://immibox.com/terms/",
    _PRIVACY:"https://immibox.com/privacy-policy/",
    showAlert:30, //show profile update alert every 30 Days 
    PDF_VIEW_URL:'https://immibox.com/viewer/pdfjs-dist/web/viewerv2.html'
 }


/*
 export default {
    _BASEURL: 'https://cms.thomasvallen.com',
    _S3URL:'https://cmsintake.s3.amazonaws.com/',
     _S3URLAWS:'https://s3.amazonaws.com/cmsintake/'
 }*/