<template>
  <div id="app">
    <router-view />
    <cookiePopup v-if="!cookiesAccepted && checkCookiePermissions" />
  </div>
</template>

<script>
import cookiePopup from '@/views/common/manageCookie.vue';

export default {
  components: {
    cookiePopup,
  },
  computed: {
    cookiesAccepted() {
      // alert()
      // if (this.checkProperty(this.$router.currentRoute, 'name') == 'Evaluations') {

      // }
      return document.cookie.includes('cookiesAccepted=true');
    },
    checkCookiePermissions() {
      if (this.getUserRoleId && [15].indexOf(this.getUserRoleId) > -1) {
        return true;
      }
      else if (this.checkProperty(this.$router.currentRoute, 'name') == 'createaccount View') {
        return true;
      }

      return false;
    },
  },
}
</script>

