import _ from "lodash"
const mutations = {
      
      auth_success(state, details){
        state.status = 'success'
        state.token = details.accessToken
        state.user = details.user
        state.role_id = details.role_id
       // state.tenantId = tenantId;
      },
     logout(state){
      //console.log(state);
        state.status = ''
        state.token = null
        state.user = null
        state.role_id = null
        state.restaurantId = "";
      },
      setRestaurantId(state, restaurantId){
        state.restaurantId = restaurantId;
      },
      setRequestsMenu(state  , menuId){
        state.selectedRequestsMenu = menuId
      },
      setFilters(state,filters){
        state.filterStatuses=filters
      }
}

export default mutations
