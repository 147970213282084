

const state = {
    status:'',
    token: localStorage.getItem('token') || '',
    user : JSON.parse(localStorage.getItem('user')) || [],
    userRole: localStorage.getItem('userRole') || '',
    windowWidth: null,
    selectedRequestsMenu:'ALL',
    restaurantId:'',
    filterStatuses:[]
  
}

export default state
